<template>
  <div class="px-2 bg-default">
    <v-btn class="indigo mb-1" dark @click="helpTimetable = true"><v-icon class="mr-1" >mdi-help-circle-outline</v-icon> Bantuan</v-btn>
    <TutorTimetable v-bind:helpTimetable="helpTimetable" @close="close"/>
    <section class="banner indigo pa-2 rounded d-flex">
      <img src="../../assets/img/calendar.svg" height="100px" />
      <div>
        <h1 class="white--text ml-3">Penjadwalan</h1>
      </div>
    </section>
    <TimetableStudent v-if="role == 4" v-bind:id_group="id_group" />
    <TimetableMentor v-if="role == 3" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TimetableStudent from "../../components/Student/timetableStudent.vue";
import TimetableMentor from "../Mentor/TimeTableMentor.vue";
import TutorTimetable from "../../components/Student/tutorial/tutorTimetable.vue"

export default {
  name: "timetables",

  components: {
    TimetableStudent,
    TimetableMentor,
    TutorTimetable,
  },
  computed: {
    ...mapState(["userProfile"]),
    id_group() {
      let id = null;
      if (this.role == 4) {
        if (this.userProfile) {
          id = parseInt(this.userProfile.grup.id);
        }
      }
      return id;
    }
  },
  data() {
    return {
      role: localStorage.getItem("role_id"),
      helpTimetable: false,
    };
  },
  methods: {
    close() {
      this.helpTimetable = false;
    }
  }
};
</script>

<style scoped>
.bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>

<template>
  <v-row>
    <v-dialog v-model="helpTimetable" persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat><h3>Penjadwalan</h3> </v-toolbar>
        <div class="py-3 px-5">
          <section class="mb-1">
            <h3>
              Menu penjadwalan dapat digunakan peserta untuk membuat sebuah
              pengingat atau memo mengenai kegiatan yang berkaitan dengan
              pengerjaan tugas. Peserta dapat melakukan pembuatan jadwal bersama
              anggota kelompoknya.
            </h3>
          </section>

          <section>
            <img
              src="../../../assets/img/tutorial/calendar-task.jpg"
              width="100%"
              alt="pic"
            />
          </section>

          <div>
            <section class="mb-1">
              <h3>
                1. Tampilan kalender yang menampilkan hari, tanggal, dan bulan.
              </h3>
            </section>
            <section class="mb-1">
              <h3>
                2. Task atau pengingat yang dibuat oleh peserta dan anggota
                kelompoknya.
              </h3>
            </section>
            <section class="mb-1">
              <h3>
                3. Pada bagian ini peserta dapat jadwal berikut dengan detailnya
                seperti nama jadwal, keterangan jadwal, waktu berakhir jadwal,
                dan prioritas jadwal.
              </h3>
            </section>
          </div>
        </div>
        <div class="px-5">
          <section>
            <img
              src="../../../assets/img/tutorial/progress.jpg"
              width="100%"
              alt="[ic"
            />
          </section>
          <div class="mb-5">
            <h3>
              Ini adalah list semua task yang telah di buat oleh anggota
              kelompok, anda bisa mengubah dengan cara
              <i>drag and drop</i> pada sebuah task
            </h3>
          </div>
          <section>
            <img
              src="../../../assets/img/tutorial/progressDetil.jpg"
              height="150"
              alt="pic"
            />
          </section>
          <div>
            <section class="mb-5">
              <h3>
                Anda juga bisa mengubah status task dengan menekan icon pada
                bagian kiri
              </h3>
            </section>
          </div>
          <section>
            <img
              src="../../../assets/img/tutorial/prioritas.jpg"
              height="150"
              alt="pic"
            />
          </section>
          <section class="pb-5">
            <h3>
              Anda juga bisa mengubah prioritas tinggi-rendahnya sebuah task
            </h3>
          </section>
        </div>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')">tutup</v-btn>
      </v-card-actions>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "helpTimetable",
  props: ["helpTimetable"],
  data() {
    return {
      close: false,
      counting: null,
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    },
  },
};
</script>

<style></style>
